import React from 'react'
import {useQuery} from '@tanstack/react-query'
import {fetchPermissions} from '../api/permissions/permissions'
import {FIVE_MINUTES_MS} from '../constants/time'

const usePermissions = () => {
  const {
    data,
    ...rest
  } = useQuery(
    ['permissions'],
    fetchPermissions,
    {
      staleTime: FIVE_MINUTES_MS,
    }
  )

  return {
    ...data,
    ...rest,
  }
}

export default usePermissions
