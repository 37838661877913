import React from 'react'
import styled from 'styled-components'
import useTextStyle, {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import PrimaryButton from './PrimaryButton'
import Spinner from '../spinner/Spinner'

const Button = styled(PrimaryButton)`
  &,
  &:focus {
    color: ${props => props?.$font?.color || 'white'};
    font-size: ${props => props?.$font?.size || '12px'};
    font-weight: ${props => props?.$font?.fontWeight || 'normal'};
  }
`

const SmallPrimaryButton = props => {
  const {
    children,
    disabled,
    loading,
    ...rest
  } = props

  const font = useTextStyle(TEXT_TYPES.detailBoldSecondary)

  return (
    <Button
      $font={font}
      disabled={disabled || loading}
      {...rest}>
      {
        loading ?
          <Spinner />
          : children
      }
    </Button>
  )
}

export default SmallPrimaryButton
