import React from 'react'
import {bool, func, node, string} from 'prop-types'
import {Card as BSCard} from 'react-bootstrap'
import styled from 'styled-components'

const propTypes = {
  children: node,
  className: string,
  clickable: bool,
  noShadow: bool,
  onClick: func,
}

const defaultProps = {
  children: null,
  className: '',
  clickable: false,
  noShadow: false,
  onClick: null,
}

const CardContainer = styled(BSCard)`
  box-shadow: ${props => props?.$noShadow ? 'none' : props?.theme?.shadows?.cards};
  border-radius: 24px;
  transition: all .3s ease-out;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  &.clickable:hover {
    box-shadow: none;
    border-color: ${props => props?.theme?.colors?.text?.hint};
  }
`

const Card = ({children, className, clickable, noShadow, onClick}) => (
  <CardContainer
    className={`${clickable && 'cursor-pointer clickable'} ${className}`}
    onClick={e => clickable && onClick ? onClick(e) : null}
    $noShadow={noShadow}>
    {children}
  </CardContainer>
)


Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card
