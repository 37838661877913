import React from 'react'
import {Image} from 'react-bootstrap'
import Text from '../../../components/text/Text'
import {TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import styled from 'styled-components'
import SmallPrimaryButton from '../../../components/buttons/SmallPrimaryButton'
import {screenMd, screenMinLg} from '../../../stylesheets/mixins/screens'
import Card from '../../../components/cards/Card'
import {v4} from 'uuid'
import {useQuery} from '@tanstack/react-query'
import {fetchNewsItems} from '../../../api/news-items/newsItems'
import Skeleton from 'react-loading-skeleton'
import {ONE_HOUR_MS} from '../../../constants/time'
import useAnalytics from '../../../hooks/useAnalytics'
import {ANALYTICS_EVENT} from '../../../constants/analytics'

const StyledSmallBtn = styled(SmallPrimaryButton)`
  ${screenMinLg({
    minWidth: '50%',
  })}
  ${screenMd({
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  })}
`

const FixedHeightCard = styled(Card)`
  ${screenMinLg(
    {
      height: '364px',
    }
  )}
`

const CardBody = styled.div`overflow: auto`

const NewsAndInformation = () => {
  const {isLoading, data} = useQuery(['news-items'], fetchNewsItems, {
    cacheTime: ONE_HOUR_MS,
    staleTime: ONE_HOUR_MS,
  })
  const analytics = useAnalytics()

  const items = data?.news_items

  const Section = ({buttonText, icon, onClick, text, title}) => (
    <div className='d-flex mb-4'>
      <Image height={110} width={110} src={icon} alt='news-and-information-section-image' />
      <div className='d-flex flex-column ms-3'>
        <Text className='d-block' suppressLocalise variant={TEXT_TYPES.subheadBoldAccent}>
          {title}
        </Text>
        <Text className='d-block mb-2' suppressLocalise variant={TEXT_TYPES.footnoteRegularPrimary}>
          {text}
        </Text>
        <div className='mt-auto'>
          <StyledSmallBtn onClick={onClick}>
            {buttonText}
          </StyledSmallBtn>
        </div>
      </div>
    </div>
  )

  const Loading = () => [1, 2].map(() => (
    <div key={v4()} className='d-flex mb-4'>
      <Skeleton height={110} width={110} />
      <div className='d-flex flex-column ms-3 w-100'>
        <Skeleton className='p-1 mb-2' />
        <Skeleton className='p-2 mb-3' />
        <Skeleton className='p-2 mb-2 w-25' />
      </div>
    </div>
  ))

  return (
    <FixedHeightCard className='p-4 my-4 my-lg-0'>
      <Text className='d-block mb-4' variant={TEXT_TYPES.bodyBoldPrimary}>
        landing.newsAndInformation
      </Text>
      <CardBody>
        {
          isLoading
            ? <Loading />
            : items?.map(item => (
              <Section
                key={v4()}
                title={item?.title}
                text={item?.description}
                icon={item?.images?.medium_url}
                buttonText={item?.button_text}
                onClick={() => {
                  analytics(ANALYTICS_EVENT.LANDING_VIEW_NEWS_INFO, {link: item?.button_url})
                  window.open(item?.button_url, '_blank')
                }}
              />
            ))
        }
      </CardBody>
    </FixedHeightCard>
  )
}

export default NewsAndInformation
