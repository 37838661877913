import {useEffect} from 'react'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {i18n} from 'next-i18next'
import useAuth from '../hooks/useAuth'
import LandingLayout from '../components/layouts/LandingLayout'
import Landing from './landing/Landing'
import {
  CASES_URL,
  CLAIMS_URL,
  DASHBOARD_URL,
  FAMILY_URL,
  LIBRARY_URL,
  PAYMENTS_URL,
  PROFILE_URL,
} from '../constants/urls'
import {useRouter} from 'next/router'
import usePermissions from '../hooks/usePermissions'
import {toggleLoading} from '../utilities/LoadingIndicatorUtil'

const App = () => {
  const {user} = useAuth()
  const {push} = useRouter()
  const {
    is_cases_allowed,
    is_dashboard_allowed,
    is_family_allowed,
    is_library_allowed,
    is_payments_allowed,
    is_profile_allowed,
    isFetching,
    isLoading,
  } = usePermissions()

  useEffect(() => {
    let destination

    if (typeof is_dashboard_allowed === 'boolean' && is_dashboard_allowed) destination = DASHBOARD_URL
    else if (typeof is_library_allowed === 'boolean' && is_library_allowed) destination = LIBRARY_URL
    else if (typeof is_cases_allowed === 'boolean' && is_cases_allowed) destination = CASES_URL
    else if (typeof is_family_allowed === 'boolean' && is_family_allowed) destination = FAMILY_URL
    else if (typeof is_profile_allowed === 'boolean' && is_profile_allowed) destination = PROFILE_URL
    else if (typeof is_payments_allowed === 'boolean' && is_payments_allowed) destination = PAYMENTS_URL
    else destination = CLAIMS_URL

    if (!!user && !!destination && !isFetching && !isLoading) {
      toggleLoading()
      push(destination)
        .finally(toggleLoading)
    }
  }, [
    isFetching,
    isLoading,
    is_cases_allowed,
    is_dashboard_allowed,
    is_family_allowed,
    is_library_allowed,
    is_profile_allowed,
    push,
    user,
  ])

  return !isLoading && !user && <LandingLayout><Landing /></LandingLayout>
}

export async function getStaticProps({locale}) {
  if (process.env.NODE_ENV === 'development') {
    await i18n?.reloadResources()
  }
  return {
    props: {
      // Will be passed to the page component as props
      ...(await serverSideTranslations(locale, ['translation'])),
    },
  }
}

export default App
