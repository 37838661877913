import React from 'react'
import styled from 'styled-components'
import StyledButton from './StyledButton'
import useTextStyle, {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'

const Button = styled(StyledButton)`
  &,
  &:focus {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    color: ${props => props?.$font?.color || 'black'};
    font-size: ${props => props?.$font?.size || '12px'};
    font-weight: ${props => props?.$font?.fontWeight || 'normal'};
    text-decoration: none;
  }

  &:active,
  &:hover:not([disabled]) {
    background-color: transparent !important;
    color: ${props => props?.$font?.color || 'black'};
    text-decoration: underline;
  }
`

const LinkButton = props => {
  const {
    children,
    font: fontProp,
    ...rest
  } = props

  const font = useTextStyle(fontProp || TEXT_TYPES.subheadBoldPrimary)

  return <Button $font={font} {...rest}>{children}</Button>
}

export default LinkButton
