import React from 'react'
import {Col, Image, Row} from 'react-bootstrap'
import {COMPANY_LOGO_LIGHT} from '../../constants/images'
import LinkButton from '../buttons/LinkButton'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import Text from '../text/Text'
import styled from 'styled-components'
import {v4} from 'uuid'
import {useSelector} from 'react-redux'
import {getSiteContactNumber} from '../../store/selectors/configurationSelectors'
import {CONTACT_US_URL, PRIVACY_POLICY_URL, RETURN_POLICY_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {useRouter} from 'next/router'
import usePermissions from '../../hooks/usePermissions'

const FooterContainer = styled(Row)`
  background-color: ${props => props?.theme?.colors?.app?.primary};
`

const Footer = () => {
  const {push: nav} = useRouter()
  const {
    is_contact_us_allowed,
    is_privacy_policy_allowed,
    is_return_policy_allowed,
  } = usePermissions()

  const siteContactNumber = useSelector(getSiteContactNumber)

  return (
    <FooterContainer className='p-lg-5 px-2 py-4 mt-3 mt-lg-0'>
      <Col xs='12' lg='5' className='text-start'>
        <Row className='g-0'>
          <Col lg='3' xs='12' className='mb-3 mb-lg-0'>
            <Image
              width={59}
              src={COMPANY_LOGO_LIGHT}
              alt={localise('alt.companyLogo')}
            />
          </Col>
          {
            [
              ...(
                is_contact_us_allowed
                  ? [{
                    onClick: () => nav(CONTACT_US_URL),
                    text: localise('buttonText.contactUs'),
                  }]
                  : []
              ),
              ...(
                is_privacy_policy_allowed
                  ? [{
                    onClick: () => nav(PRIVACY_POLICY_URL),
                    text: localise('buttonText.privacyPolicy'),
                  }]
                  : []
              ),
              ...(
                is_return_policy_allowed
                  ? [{
                    onClick: () => nav(RETURN_POLICY_URL),
                    text: localise('buttonText.returnPolicy'),
                  }]
                  : []
              ),
            ].map((
              {
                onClick,
                text,
              }
            ) => (
              <Col xs='3' key={v4()}>
                <LinkButton font={TEXT_TYPES.footnoteBoldSecondary} onClick={onClick}>
                  {text}
                </LinkButton>
              </Col>
            ))
          }
        </Row>
      </Col>
      <Col xs='12' lg='7' className='text-lg-end text-start'>
        <Row>
          <Col className='mt-3 mt-lg-0'>
            <Text className='d-block' variant={TEXT_TYPES.footnoteBoldSecondary} suppressLocalise>
              {localise('footer.questionAboutSite', {siteContactNumber})}
            </Text>
            <Text className='d-block mt-2' variant={TEXT_TYPES.detailRegularSecondary}>
              footer.copyrightText
            </Text>
          </Col>
        </Row>
      </Col>
    </FooterContainer>
  )
}

export default Footer
