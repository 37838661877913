import React from 'react'
import {node} from 'prop-types'
import {Col, Container, Image, NavbarBrand, Row} from 'react-bootstrap'
import {COMPANY_LOGO} from '../../constants/images'
import {ROOT_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'

const propTypes = {children: node}
const defaultProps = {children: null}

const LandingLayout = ({children}) => {

  return (
    <Container fluid className='min-vh-100 d-flex flex-column'>
      <Row className='bg-secondary px-lg-5 px-2'>
        <Col xs='12'>
          <NavbarBrand href={ROOT_URL} className=''>
            <Image height={100} src={COMPANY_LOGO} alt={localise('alt.companyLogo')} />
          </NavbarBrand>
        </Col>
      </Row>
      {children}
    </Container>
  )
}

LandingLayout.propTypes = propTypes
LandingLayout.defaultProps = defaultProps

export default LandingLayout
