import React from 'react'
import Text from '../../../components/text/Text'
import {TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import {Col, Row} from 'react-bootstrap'
import styled from 'styled-components'
import Card from '../../../components/cards/Card'
import {v4} from 'uuid'
import {screenMinLg} from '../../../stylesheets/mixins/screens'
import {useQuery} from '@tanstack/react-query'
import {fetchHelpfulLinks} from '../../../api/helpful-links/helpfulLinks'
import {ONE_HOUR_MS} from '../../../constants/time'
import Skeleton from 'react-loading-skeleton'
import useAnalytics from '../../../hooks/useAnalytics'
import {ANALYTICS_EVENT} from '../../../constants/analytics'

const FixedHeightCard = styled(Card)`
  ${screenMinLg(
    {
      height: '364px',
    }
  )}
`

const Link = styled.div`
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  min-height: 110px;
  transition: border .2s;
  &:hover {
    border-color: ${props => props?.theme?.colors?.text?.hint};
    cursor: pointer;
  }
`

const HelpfulLinks = () => {
  const {isLoading, data} = useQuery(['helpful_links'], fetchHelpfulLinks, {
    cacheTime: ONE_HOUR_MS,
    staleTime: ONE_HOUR_MS,
  })
  const analytics = useAnalytics()

  const links = data?.helpful_links

  const Loading = () => [1, 2, 3, 4].map(() => <Col key={v4()} xs='12' lg='6'><Skeleton height={110} /></Col>)

  const backgrounds = [
    'bg-brand-1-50',
    'bg-accent-50',
    'bg-background',
    'bg-brand-2-50',
  ]

  const openHelpfulLink = url => {
    analytics(ANALYTICS_EVENT.LANDING_VIEW_HELPFUL_LINK, {link: url})
    window.open(url, '_blank')
  }

  return (
    <FixedHeightCard className='p-4 my-4 my-lg-0'>
      <Text className='d-block mb-4' variant={TEXT_TYPES.bodyBoldPrimary}>
        landing.helpfulLinks
      </Text>
      <Row className='g-3 pt-2'>
        {
          isLoading
            ? <Loading />
            : links?.map(({
              url,
              description,
              title,
            }, i) => (
              <Col key={v4()} xs='12' lg='6'>
                <Link
                  tabIndex='0'
                  role='button'
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      openHelpfulLink(url)
                    }
                  }}
                  onClick={() => openHelpfulLink(url)}
                  className={`${backgrounds[i]} p-3`}>
                  <Text suppressLocalise className='d-block' variant={TEXT_TYPES.subheadBoldPrimary}>
                    {title}
                  </Text>
                  <Text suppressLocalise className='d-block' variant={TEXT_TYPES.footnoteRegularPrimary}>
                    {description}
                  </Text>
                </Link>
              </Col>
            ))
        }
      </Row>
    </FixedHeightCard>
  )
}

export default HelpfulLinks
