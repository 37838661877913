import React, {useEffect} from 'react'
import {Col, Image, Row} from 'react-bootstrap'
import {BUILDERS} from '../../constants/images'
import Text from '../../components/text/Text'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import {localise} from '../../services/LocalizationServices'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import NewsAndInformation from './sections/NewsAndInformation'
import HelpfulLinks from './sections/HelpfulLinks'
import Footer from '../../components/footer/Footer'
import {AUTH_URL, SIGN_IN_URL, SIGN_UP_URL} from '../../constants/urls'
import {useRouter} from 'next/router'
import useAnalytics from '../../hooks/useAnalytics'
import {ANALYTICS_EVENT} from '../../constants/analytics'

const Landing = () => {
  const {push: nav} = useRouter()
  const analytics = useAnalytics()

  const toSignIn = () => nav(`${AUTH_URL}${SIGN_IN_URL}`)
  const toSignUp = () => nav(`${AUTH_URL}${SIGN_UP_URL}`)

  useEffect(() => {
    analytics(ANALYTICS_EVENT.LANDING_VIEW)
  }, [])

  return (
    <div className='d-flex flex-column flex-grow-1'>
      <Row className='bg-secondary pb-lg-5 pb-2 px-lg-5 px-2'>
        <Col lg='7' className='d-flex flex-column'>
          <Text className='d-block mt-lg-5' variant={TEXT_TYPES.H2BoldPrimary}>
            landing.buildingAmericaText
          </Text>
          <Text className='d-block' variant={TEXT_TYPES.H2BoldPrimary}>
            landing.welcomeToMy150Text
          </Text>
          <div className='mt-4 mt-lg-auto'>
            <PrimaryButton
              onClick={toSignIn}
              className='text-nowrap px-5'>
              {localise('buttonText.signIn')}
            </PrimaryButton>
            <SecondaryButton
              onClick={toSignUp}
              className='ms-3 text-nowrap px-5'>
              {localise('buttonText.signUp')}
            </SecondaryButton>
          </div>
        </Col>
        <Col lg='5' className='d-flex'>
          <Image className='ms-lg-auto mw-100 mt-4 mt-lg-0' src={BUILDERS} alt={localise('alt.builders')} />
        </Col>
      </Row>
      <Row className='p-lg-5 px-2 pt-4 mb-auto'>
        <Col>
          <NewsAndInformation />
        </Col>
        <Col>
          <HelpfulLinks />
        </Col>
      </Row>
      <Footer />
    </div>
  )
}

export default Landing
